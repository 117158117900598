<template>
  <a-page-header @back="() => $router.go(-1)" title="INDICADOR RESUMEN" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Proyecto</router-link></a-breadcrumb-item>
        <a-breadcrumb-item
          ><router-link to="/indicador-establecimiento"
            >Indicador para establecimiento</router-link
          ></a-breadcrumb-item
        >
        <a-breadcrumb-item>Resumen</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <!--------------- Filtros --------------->
  <div class="flex flex-wrap mb-4">
    <fieldset
      class="border border-solid w-full border-gray-300 flex justify-center items-center px-8 lg:w-auto"
    >
      <legend class="text-sm text-center m-0">Filtros</legend>
      <IndicadorResumenFiltro
        :idperiodoinicio="idperiodoinicio"
        :sigla="indicadorData.sigla"
        :periodos="periodos"
        @changedistrito="changedistrito"
        @changePeriodo="changePeriodo"
        @changeprovincia="changeprovincia"
      />
    </fieldset>

    <fieldset
      class="border border-solid w-full border-gray-300 flex justify-center items-center px-8 lg:w-auto"
    >
      <legend class="text-sm text-center m-0">Acciones</legend>
      <div class="flex flex-wrap gap-8">
        <router-link
          :to="'/indicador-establecimiento/' + idindicador + '/detalles'"
          class="text-center"
        >
          <div class="text-3xl text-green-400">
            <FileSearchOutlined />
          </div>
          <p>Ver detalle</p>
        </router-link>

        <button @click="handleReporteExcel" class="text-center" :disabled="isAccionsDisabled">
          <div
            class="text-3xl"
            :class="[isAccionsDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-green-700']"
          >
            <FileExcelOutlined />
          </div>
          <p>reporte</p>
        </button>

        <button
          @click="showModalPrintModal"
          type="submit"
          class="text-center"
          :disabled="isAccionsDisabled"
        >
          <div
            class="text-3xl"
            :class="[isAccionsDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-red-400']"
          >
            <FilePdfOutlined />
          </div>
          <p>Ver PDF</p>
        </button>

        <button @click="handleReporteIndicador" class="text-center">
          <div class="text-3xl" :class="'text-green-500'">
            <FileTextOutlined />
          </div>
          <p>Ver Ficha</p>
        </button>
      </div>
    </fieldset>
  </div>
  <!--------------- ./ Filtros Acciones Desktop--------------->

  <div v-if="isAccionsDisabled && !isLoading">
    <a-result
      status="info"
      title="Aún no hay información para mostrar"
      sub-title="Primero debes crear el query y la sigla en la base de datos para el presente indicador para luego ejecutarlo en 'Periodo de evaluación'"
    >
      <template #extra>
        <router-link to="/indicador">
          <a-button type="primary">Ir a indicadores</a-button>
        </router-link>
      </template>
    </a-result>
  </div>

  <div v-else>
    <TituloPorcentajeIndicador
      :isLoading="isLoading"
      :porcentaje="porcentaje"
      :abreviatura="indicadorData.abreviatura"
      :denominacion="indicadorData.denominacion"
      :numerador="numerador"
      :titulonumerado="indicadorData.titulonumerado"
      :denominador="denominador"
      :titulodenominado="indicadorData.titulodenominado"
      :porcentajeColor="porcentajeColor"
    />

    <a-result
      v-if="!periodos.length && !isPeriodoProcessing"
      status="info"
      title="No hay información para mostrar"
      sub-title="Puedes generar un periodo de evaluación para poder ver la información"
    >
      <template #extra>
        <a-button @click="showModalPeriodoEvaluacion" type="primary">
          Crear periodo de evaluación
        </a-button>
      </template>
    </a-result>

    <a-result
      v-if="isPeriodoProcessing"
      title="Procesando indicador"
      sub-title="Se está ejecutando el query para generar la información necesaria del indicador"
    >
      <template #icon>
        <AppLoading />
      </template>

      <template #extra>
        <a-button @click="showModalPeriodoEvaluacion" type="primary">
          Ver periodo de evaluación
        </a-button>
      </template>
    </a-result>

    <div v-if="periodos.length && !isPeriodoProcessing" class="lg:flex gap-8">
      <div class="w-full lg:w-1/2">
        <div class="text-center mt-2">
          <h1 class="p-2 bg-white font-semibold border border-gray-400">
            Indicador por establecimientos
          </h1>
          <span><CaretDownOutlined /></span>
        </div>

        <div class="max-w-full px-4 py-4 mx-auto overflow-x-auto">
          <a-table
            :columns="columnsIndicadorEstablecimiento"
            :data-source="data"
            size="small"
            :pagination="{ size: 'large' }"
            :custom-row="customRow"
            rowKey="establecimiento"
            :rowClassName="
              (record, index) =>
                index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
            "
          >
            <template #rangepages="{ record }">
              <span> {{ data.indexOf(record) + 1 }} </span>
            </template>

            <template #porcentaje="{ record, text: porcentaje }">
              <div class="flex gap-4 justify-between items-center">
                <div
                  class="w-3 h-3 rounded-full"
                  :style="{ background: record.porcentajeHex }"
                ></div>
                <p class="font-bold">{{ porcentaje }} % &nbsp;</p>
                <p class="text-xs text-gray-400">
                  {{ record.total_numerador_establecimiento }} /
                  {{ record.total_denominador_establecimiento }}
                </p>
              </div>
            </template>
          </a-table>
        </div>
      </div>

      <div class="w-full lg:w-1/2 max-w-full mx-auto overflow-x-auto lg:overflow-hidden">
        <div class="text-center mt-2">
          <h1 class="p-2 bg-white font-semibold border border-gray-400">
            Evolución del indicador en el periodo de evaluación {{ ragoPeriodos }}
          </h1>
          <span><CaretDownOutlined /></span>
        </div>
        <div class="w-full">
          <v-chart
            :option="optionGraphic"
            style="width: 100%; height: 400px; background-color: white"
          />
        </div>
      </div>
    </div>

    <PeriodoEvaluacion
      v-if="isVisible"
      :isVisible="isVisible"
      :query="indicadorData.query"
      :sigla="indicadorData.sigla"
      :idindicador="idindicador"
      @closeModal="closeModalPeriodoEvaluacion"
      @getResumenIndicador="getResumenIndicador"
    />

    <a-modal
      :visible="isVisiblePrintModal"
      @close="closeModalPrintModal"
      @cancel="closeModalPrintModal"
      :footer="null"
      class="max-w-sm md:max-w-5xl text-center"
      width="100%"
    >
      <a-button
        shape="round"
        v-print="objPrintIndicadorResumen"
        size="large"
        class="text-center mx-auto mb-8 bg-red-500 text-white"
      >
        <template #icon>
          <PrinterOutlined />
          Imprimir
        </template>
      </a-button>

      <div id="printIndicadorResumen">
        <PrintIndicadorResumen :indicadorEstablecimientos="data">
          <template #header>
            <TituloPorcentajeIndicador
              :isLoading="isLoading"
              :porcentaje="porcentaje"
              :abreviatura="indicadorData.abreviatura"
              :denominacion="indicadorData.denominacion"
              :numerador="numerador"
              :titulonumerado="indicadorData.titulonumerado"
              :denominador="denominador"
              :titulodenominado="indicadorData.titulodenominado"
              :porcentajeColor="porcentajeColor"
            />
          </template>

          <template #grafico>
            <v-chart
              :option="optionGraphic"
              style="width: 100%; height: 400px; background-color: white"
            />
          </template>
        </PrintIndicadorResumen>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ref, toRaw, onMounted, reactive, watch, computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import IndicadorResumenFiltro from "./IndicadorResumenFiltro.vue";
import PeriodoEvaluacion from "./IndicadorResumenPeriodoEvaluacion.vue";
import PrintIndicadorResumen from "@/print/PrintIndicadorResumen.vue";
import TituloPorcentajeIndicador from "@/components/TituloPorcentajeIndicador.vue";
import router from "@/router";
import ApiIndicador from "@/api/indicador";
import ApiMapa from "@/api/mapa";
import ApiDescargas from "@/api/descargas";
import {
  calculaPorcentaje,
  semaforoByPorcentajeIndicador,
  semaforoPorcentajeIndicadorHex
} from "@/utils";
import { columnsIndicadorEstablecimiento, optionsBar } from "./utilsIndicador";
import {
  FileSearchOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  CaretDownOutlined,
  PrinterOutlined,
  FileTextOutlined
} from "@ant-design/icons-vue";
import AppLoading from "@/components/AppLoading.vue";

export default {
  components: {
    IndicadorResumenFiltro,
    PeriodoEvaluacion,
    PrintIndicadorResumen,
    AppLoading,
    TituloPorcentajeIndicador,
    // icons
    FileSearchOutlined,
    FileExcelOutlined,
    FilePdfOutlined,
    CaretDownOutlined,
    PrinterOutlined,
    FileTextOutlined
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const idindicador = ref("");
    const data = ref([]);
    const isVisiblePrintModal = ref(false);

    const isVisible = ref(false);
    const isLoadingGrafico = ref(false);
    const isPeriodoProcessing = ref(false);
    const porcentaje = ref("");
    const numerador = ref("");
    const denominador = ref("");
    const isLoading = ref(false);
    const isAccionsDisabled = ref(true);
    const optionGraphic = ref(null);
    const periodos = ref([]);
    const idperiodoinicio = ref("");
    const RANGO_SINDATO = ref(40);
    const RANGO_BAJO = ref(60);
    const RANGO_MEDIO = ref(80);
    const porcentajeColor = ref("");

    const objPrintIndicadorResumen = reactive({
      id: "printIndicadorResumen",
      popTitle: "SIVINUR | RESUMEN DE INDICADOR"
    });

    const indicadorData = reactive({
      denominacion: "",
      fichatecnica: "",
      titulonumerado: "",
      titulodenominado: "",
      tituloreporte: "",
      abreviatura: "",
      query: "",
      sigla: ""
    });

    idindicador.value = route.params?.indicador;

    // ------- onBack -------
    const getResumenIndicador = () => {
      store.dispatch("mapa/setUbigeo", "");
      isLoading.value = true;

      ApiIndicador.getInfo(toRaw(idindicador.value))
        .then(async (response) => {
          indicadorData.denominacion = response.data[0].denominacion;
          indicadorData.query = response.data[0].query;
          indicadorData.fichatecnica = response.data[0].fichatecnica;
          indicadorData.titulonumerado = response.data[0].titulonumerado;
          indicadorData.titulodenominado = response.data[0].titulodenominado;
          indicadorData.tituloreporte = response.data[0].tituloreporte;
          indicadorData.abreviatura = response.data[0].abreviatura;
          indicadorData.sigla = response.data[0].sigla;

          store.dispatch("mapa/setSigla", response.data[0].sigla);

          await fetchPeriodos(indicadorData.sigla);

          isAccionsDisabled.value = !response.data[0].query && response.data[0].sigla == null;

          changePeriodo();
          changeGrafica();
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    const fetchPeriodos = async (sigla) => {
      console.log(sigla);

      var response = await ApiIndicador.getperiodoByIndicador(sigla);
      periodos.value = response.data;

      var periodosValue = toRaw(periodos.value);
      var periodoEval = periodosValue[periodosValue.length - 1];
      var idperiodoevaluacion = periodoEval?.idperiodoevaluacion;
      idperiodoinicio.value = idperiodoevaluacion;

      store.dispatch("mapa/setPeriodo", idperiodoevaluacion);
    };

    const fetchSemaforoIndicador = () => {
      ApiIndicador.getPorcentajeColor(toRaw(idindicador.value))
        .then((res) => {
          RANGO_SINDATO.value =
            res.data.find((parametro) => parametro.clave === "RANGO_SINDATO")?.valor || 40;
          RANGO_BAJO.value =
            res.data.find((parametro) => parametro.clave === "RANGO_BAJO")?.valor || 60;
          RANGO_MEDIO.value =
            res.data.find((parametro) => parametro.clave === "RANGO_MEDIO")?.valor || 80;
        })
        .catch((err) => {
          console.log(err);
        });
    };

    onMounted(() => {
      getResumenIndicador();
      fetchSemaforoIndicador();
    });

    watch(store.state.periodoevaluacion, (periodoevaluacion) => {
      isPeriodoProcessing.value = periodoevaluacion.isProcessing;
      getResumenIndicador();
    });

    const changeprovincia = () => {
      getResumenIndicador();
    };

    const ragoPeriodos = computed(() => {
      // console.log(${periodos[0].idperiodoinicio} al ${periodos[periodos.length].idperiodofinal} )
      const staticPeriodo = toRaw(periodos.value);

      const position = 4;

      const periodoInicio = staticPeriodo[0].idperiodoinicio;

      /**
       * Inserting string at position x of another string
       * https://stackoverflow.com/a/4364902/9868383
       */
      const periodoInicioSlash = [
        periodoInicio.slice(0, position),
        "/",
        periodoInicio.slice(position)
      ].join("");

      const periodoFinal = staticPeriodo[staticPeriodo.length - 1].idperiodofinal;

      const periodoFinalSlash = [
        periodoFinal.slice(0, position),
        "/",
        periodoFinal.slice(position)
      ].join("");

      return `${periodoInicioSlash} al ${periodoFinalSlash}`;
    });

    const changeGrafica = () => {
      const ubigeo = store.getters["mapa/ubigeo"];
      const provincia = store.getters["mapa/provincia"];
      const periodo = store.getters["mapa/periodo"];

      const distrito = "";
      isLoadingGrafico.value = true;

      if (ubigeo == "") {
        ApiIndicador.getIndicadorByIdperiodo(
          distrito,
          toRaw(idindicador.value),
          toRaw(indicadorData.sigla),
          provincia,
          periodo
        )
          .then(async (response) => {
            var puntosEntrada = [];
            var numerador = [];
            var denominador = [];
            var tendencia = [];
            var max = 0;

            response.data.forEach((e) => {
              puntosEntrada.push(
                e.idperiodo.substr(0, 4) +
                  "/" +
                  e.idperiodo.substr(4, 6) +
                  " - " +
                  e.idperiodo.substr(0, 4) +
                  "/" +
                  e.idperiodo.substr(4, 6)
              );
              numerador.push(e.numerador);
              denominador.push(e.denominador);
              tendencia.push(e.numerador);

              if (parseInt(e.denominador) > max) max = parseInt(e.denominador);
            });

            optionGraphic.value = optionsBar(puntosEntrada, numerador, denominador, tendencia, max);
          })
          .catch((err) => console.log(err))
          .finally(() => (isLoadingGrafico.value = false));
      } else {
        ApiMapa.getDistrito(ubigeo)
          .then((response) => {
            const periodo = store.getters["mapa/periodo"];
            const distrito = response.data[0].distrito;
            ApiIndicador.getIndicadorByIdperiodo(
              distrito,
              toRaw(idindicador.value),
              toRaw(indicadorData.sigla),
              provincia,
              periodo
            )
              .then(async (response) => {
                // points.value = await generatePoints(response.data);
                var puntosEntrada = [];
                var numerador = [];
                var denominador = [];
                var tendencia = [];
                var max = 0;
                // idperiodofinal, idperiodoinicio, numerador, denominador
                response.data.forEach((e) => {
                  puntosEntrada.push(
                    e.idperiodo.substr(0, 4) +
                      "/" +
                      e.idperiodo.substr(4, 6) +
                      " - " +
                      e.idperiodo.substr(0, 4) +
                      "/" +
                      e.idperiodo.substr(4, 6)
                  );
                  numerador.push(e.numerador);
                  denominador.push(e.denominador);
                  tendencia.push(e.numerador);

                  if (parseInt(e.denominador) > max) max = parseInt(e.denominador);
                });

                optionGraphic.value = optionsBar(
                  puntosEntrada,
                  numerador,
                  denominador,
                  tendencia,
                  max
                );
              })
              .catch((err) => console.log(err))
              .finally(() => (isLoadingGrafico.value = false));
          })
          .catch((err) => console.log(err));
      }
    };

    const changedistrito = () => {
      const ubigeo = store.getters["mapa/ubigeo"];
      const periodo = store.getters["mapa/periodo"];
      const provincia = store.getters["mapa/provincia"];

      isLoading.value = true;

      if (!ubigeo) {
        const distrito = "";
        store.dispatch("mapa/setDistrito", distrito);

        ApiIndicador.getIndicador(distrito, periodo, indicadorData.sigla, provincia)
          .then((response) => {
            console.log("Indicador response.data no ubigeo", response.data);
            data.value = response.data;

            numerador.value = response.data[0].total_numerador_distrito;
            denominador.value = response.data[0].total_denominador_distrito;

            porcentaje.value = calculaPorcentaje(toRaw(numerador.value), toRaw(denominador.value));

            console.log("porcentaje 1", porcentaje.value);
          })
          .catch((err) => console.log(err))
          .finally(() => {
            isLoading.value = false;
            if (periodo.length == 0) changeGrafica();
            else changeGraficaPeriodo(periodo);
          });
      } else {
        ApiMapa.getDistrito(ubigeo)
          .then((response) => {
            const distrito = response.data[0].distrito;

            store.dispatch("mapa/setDistrito", distrito);

            ApiIndicador.getIndicador(distrito, periodo, indicadorData.sigla, provincia)
              .then((response) => {
                // data.value = response.data; // SE USA DATA
                // console.log("Indicador response.data ubigeo", response.data);

                if (response.data.length) {
                  numerador.value = response.data[0].total_numerador_distrito;
                  denominador.value = response.data[0].total_denominador_distrito;
                  porcentaje.value = calculaPorcentaje(
                    toRaw(numerador.value),
                    toRaw(denominador.value)
                  );

                  const conPorcentajes = response.data.map((p) => {
                    const porcentaje = calculaPorcentaje(
                      p.total_numerador_establecimiento,
                      p.total_denominador_establecimiento
                    );

                    const porcentajeHex = semaforoPorcentajeIndicadorHex(
                      RANGO_BAJO.value,
                      RANGO_MEDIO.value,
                      porcentaje
                    );

                    return {
                      ...p,
                      porcentaje,
                      porcentajeHex
                    };
                  });

                  data.value = conPorcentajes;

                  console.log("porcentaje 2", porcentaje.value);
                } else {
                  porcentaje.value = 0;
                  numerador.value = 0;
                  denominador.value = 0;
                  data.value = [];
                }
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err))
          .finally(() => {
            isLoading.value = false;
            if (periodo.length == 0) changeGrafica();
            else changeGraficaPeriodo(periodo);
          });
      }
    };

    const changeGraficaPeriodo = (idperiodo) => {
      const ubigeo = store.getters["mapa/ubigeo"];
      const provincia = store.getters["mapa/provincia"];
      const distrito = "";
      isLoadingGrafico.value = true;

      console.log(idperiodo);

      if (ubigeo == "") {
        ApiIndicador.getIndicadorByIdperiodoMeses(
          distrito,
          toRaw(idindicador.value),
          toRaw(indicadorData.sigla),
          provincia,
          idperiodo
        )
          .then(async (response) => {
            var puntosEntrada = [];
            var numerador = [];
            var denominador = [];
            var tendencia = [];
            var max = 0;

            response.data.forEach((e) => {
              puntosEntrada.push(e.idperiodo.substr(0, 4) + "/" + e.idperiodo.substr(4, 6));
              numerador.push(e.numerador);
              denominador.push(e.denominador);
              tendencia.push(e.numerador);

              if (parseInt(e.denominador) > max) max = parseInt(e.denominador);
            });

            console.log(max);
            optionGraphic.value = optionsBar(puntosEntrada, numerador, denominador, tendencia, max);
          })
          .catch((err) => console.log(err))
          .finally(() => (isLoadingGrafico.value = false));
      } else {
        ApiMapa.getDistrito(ubigeo)
          .then((response) => {
            const distrito = response.data[0].distrito;
            ApiIndicador.getIndicadorByIdperiodoMeses(
              distrito,
              toRaw(idindicador.value),
              toRaw(indicadorData.sigla),
              provincia,
              idperiodo
            )
              .then(async (response) => {
                var puntosEntrada = [];
                var numerador = [];
                var denominador = [];
                var max = 0;
                response.data.forEach((e) => {
                  puntosEntrada.push(e.idperiodo.substr(0, 4) + "/" + e.idperiodo.substr(4, 6));
                  numerador.push(e.numerador);
                  denominador.push(e.denominador);

                  if (parseInt(e.denominador) > max) max = parseInt(e.denominador);
                });

                optionGraphic.value = optionsBar(
                  puntosEntrada,
                  numerador,
                  denominador,
                  numerador,
                  max
                );
              })
              .catch((err) => console.log(err))
              .finally(() => (isLoadingGrafico.value = false));
          })
          .catch((err) => console.log(err));
      }
    };

    const changePeriodo = () => {
      const periodo = store.getters["mapa/periodo"];
      const distrito = store.getters["mapa/distrito"];
      const provincia = store.getters["mapa/provincia"];
      isLoading.value = true;

      ApiIndicador.getIndicador(distrito, periodo, toRaw(indicadorData.sigla), provincia)
        .then((response) => {
          console.log("changePeriodo", response.data);

          if (response.data.length > 0) {
            porcentaje.value = calculaPorcentaje(
              response.data[0].total_numerador_distrito,
              response.data[0].total_denominador_distrito
            );

            const conPorcentajes = response.data.map((p) => {
              const porcentaje = calculaPorcentaje(
                p.total_numerador_establecimiento,
                p.total_denominador_establecimiento
              );

              const porcentajeHex = semaforoPorcentajeIndicadorHex(
                RANGO_BAJO.value,
                RANGO_MEDIO.value,
                porcentaje
              );

              return {
                ...p,
                porcentaje,
                porcentajeHex
              };
            });

            data.value = conPorcentajes;

            porcentajeColor.value = semaforoByPorcentajeIndicador(
              RANGO_SINDATO.value,
              RANGO_BAJO.value,
              RANGO_MEDIO.value,
              porcentaje.value
            );

            numerador.value = response.data[0].total_numerador_distrito;
            denominador.value = response.data[0].total_denominador_distrito;
          } else {
            porcentaje.value = 0;
            numerador.value = 0;
            denominador.value = 0;
            data.value = [];
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          isLoading.value = false;
          if (periodo.length == 0) changeGrafica();
          else changeGraficaPeriodo(periodo);
          // changeGrafica(); // no se actualiza la grafica, no hay casos por dias
        });
    };

    const showModalPeriodoEvaluacion = () => {
      isVisible.value = true;
    };

    const closeModalPeriodoEvaluacion = () => {
      isVisible.value = false;
    };

    const handleReporteExcel = () => {
      ApiDescargas.getReporteIndicadorV2(
        toRaw(indicadorData.sigla),
        "indicador_reporte",
        toRaw(indicadorData.denominacion),
        numerador.value,
        denominador.value,
        toRaw(porcentaje.value)
      )
        .then(() => {})
        .catch((err) => console.log(err));
    };

    const handleReporteIndicador = () => {
      window.open(`${process.env.VUE_APP_BASE_API}/FICHAS/${indicadorData.sigla}.pdf`, "_blank");
    };

    // establecimiento
    const customRow = (record) => {
      return {
        onClick: () => {
          router.push(
            `/indicador-establecimiento/${idindicador.value}/establecimiento/${record.idestablecimiento}`
          );
        }
      };
    };

    const showModalPrintModal = () => {
      isVisiblePrintModal.value = true;
    };
    const closeModalPrintModal = () => {
      isVisiblePrintModal.value = false;
    };

    return {
      idindicador,
      data,
      // onFilter,
      closeModalPeriodoEvaluacion,
      isVisible,
      porcentaje,
      numerador,
      changedistrito,
      showModalPeriodoEvaluacion,
      denominador,
      // points,
      changePeriodo,
      indicadorData,
      isLoading,
      getResumenIndicador,
      isAccionsDisabled,
      handleReporteExcel,
      isLoadingGrafico,
      calculaPorcentaje,
      optionGraphic,
      periodos,
      isPeriodoProcessing,
      changeprovincia,
      columnsIndicadorEstablecimiento,
      ragoPeriodos,
      customRow,
      objPrintIndicadorResumen,
      isVisiblePrintModal,
      showModalPrintModal,
      closeModalPrintModal,
      idperiodoinicio,
      porcentajeColor,
      handleReporteIndicador
      // handleReportePDF
    };
  }
};
</script>

<style></style>
